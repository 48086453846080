@tailwind base; 
@tailwind components; 
@tailwind utilities;


$theme-colors: (
  'primary': #0d6efd,
  'secondary': #6c757d,
  'success': #198754,
  'info': #0dcaf0,
  'warning': #ffc107,
  'danger': #dc3545,
  'light': #f8f9fa,
  'dark': #212529,
  'purple': #8b04eb,
  'purple-900': #190d44,
  'purple-500': #653CAD,
  'purple-100':#eae6f7 ,
  'blue-100':#b6c6ff,
  'white': #FFFCFC,
);




.alert {
    /* background: linear-gradient(to right, #4828FE, #964DFE); */
    color: white;
    padding: 10px;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    box-shadow:  0 3px 10px rgb(0 0 0 / 0.2);
    font-family: Arial;
    width: 400px;
    box-sizing: border-box;
    font-size: 15px;
    padding:20px;
    z-index: 999999 !important;
    position:relative;
}


@media only screen and (max-width: 414px) {  
    .alert {
        width:300px !important;
        z-index: 3000 !important;
    }
}

@media only screen and (max-width: 300px) {  
    .alert {
        width:250px !important;
        padding:10px;
        z-index: 3000 !important;
    }
}
  
.button{
    margin-left: 20px;
    border:none;
    background-color: transparent;
    cursor: pointer;
    color:white;
    align-self:flex-start;
  }
  
.icon {
    margin-right:15px;
    font-size:20px;
    color:white
  }